/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentIntentOffer } from './PaymentIntentOffer';
import {
  PaymentIntentOfferFromJSON,
  PaymentIntentOfferFromJSONTyped,
  PaymentIntentOfferToJSON,
  PaymentIntentOfferToJSONTyped,
} from './PaymentIntentOffer';

/**
 *
 * @export
 * @interface PaymentIntentOffers
 */
export interface PaymentIntentOffers {
  /**
   *
   * @type {Array<PaymentIntentOffer>}
   * @memberof PaymentIntentOffers
   */
  offers?: Array<PaymentIntentOffer>;
}

/**
 * Check if a given object implements the PaymentIntentOffers interface.
 */
export function instanceOfPaymentIntentOffers(value: object): value is PaymentIntentOffers {
  return true;
}

export function PaymentIntentOffersFromJSON(json: any): PaymentIntentOffers {
  return PaymentIntentOffersFromJSONTyped(json, false);
}

export function PaymentIntentOffersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentIntentOffers {
  if (json == null) {
    return json;
  }
  return {
    offers: json['offers'] == null ? undefined : (json['offers'] as Array<any>).map(PaymentIntentOfferFromJSON),
  };
}

export function PaymentIntentOffersToJSON(json: any): PaymentIntentOffers {
  return PaymentIntentOffersToJSONTyped(json, false);
}

export function PaymentIntentOffersToJSONTyped(
  value?: PaymentIntentOffers | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    offers: value['offers'] == null ? undefined : (value['offers'] as Array<any>).map(PaymentIntentOfferToJSON),
  };
}
