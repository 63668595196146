/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LiquidityProvider } from './LiquidityProvider';
import {
  LiquidityProviderFromJSON,
  LiquidityProviderFromJSONTyped,
  LiquidityProviderToJSON,
  LiquidityProviderToJSONTyped,
} from './LiquidityProvider';
import type { PayoutStatus } from './PayoutStatus';
import {
  PayoutStatusFromJSON,
  PayoutStatusFromJSONTyped,
  PayoutStatusToJSON,
  PayoutStatusToJSONTyped,
} from './PayoutStatus';
import type { PayoutFailureReason } from './PayoutFailureReason';
import {
  PayoutFailureReasonFromJSON,
  PayoutFailureReasonFromJSONTyped,
  PayoutFailureReasonToJSON,
  PayoutFailureReasonToJSONTyped,
} from './PayoutFailureReason';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
  CryptoWalletAddressWithTagToJSONTyped,
} from './CryptoWalletAddressWithTag';
import type { PayoutOrder } from './PayoutOrder';
import {
  PayoutOrderFromJSON,
  PayoutOrderFromJSONTyped,
  PayoutOrderToJSON,
  PayoutOrderToJSONTyped,
} from './PayoutOrder';
import type { FailureUrl } from './FailureUrl';
import { FailureUrlFromJSON, FailureUrlFromJSONTyped, FailureUrlToJSON, FailureUrlToJSONTyped } from './FailureUrl';
import type { CryptoCurrencyMetadata } from './CryptoCurrencyMetadata';
import {
  CryptoCurrencyMetadataFromJSON,
  CryptoCurrencyMetadataFromJSONTyped,
  CryptoCurrencyMetadataToJSON,
  CryptoCurrencyMetadataToJSONTyped,
} from './CryptoCurrencyMetadata';
import type { FiatMoney } from './FiatMoney';
import { FiatMoneyFromJSON, FiatMoneyFromJSONTyped, FiatMoneyToJSON, FiatMoneyToJSONTyped } from './FiatMoney';
import type { SuccessUrl } from './SuccessUrl';
import { SuccessUrlFromJSON, SuccessUrlFromJSONTyped, SuccessUrlToJSON, SuccessUrlToJSONTyped } from './SuccessUrl';
import type { OrderId } from './OrderId';
import { OrderIdFromJSON, OrderIdFromJSONTyped, OrderIdToJSON, OrderIdToJSONTyped } from './OrderId';
import type { TrafficProvider } from './TrafficProvider';
import {
  TrafficProviderFromJSON,
  TrafficProviderFromJSONTyped,
  TrafficProviderToJSON,
  TrafficProviderToJSONTyped,
} from './TrafficProvider';

/**
 *
 * @export
 * @interface Payout
 */
export interface Payout {
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof Payout
   */
  id: string;
  /**
   * Opaque object identifier
   * @type {string}
   * @memberof Payout
   */
  cryptoExecutionId?: string;
  /**
   *
   * @type {PayoutOrder}
   * @memberof Payout
   */
  order?: PayoutOrder;
  /**
   *
   * @type {OrderId}
   * @memberof Payout
   */
  orderId: OrderId;
  /**
   *
   * @type {TrafficProvider}
   * @memberof Payout
   */
  trafficProvider: TrafficProvider;
  /**
   *
   * @type {LiquidityProvider}
   * @memberof Payout
   */
  liquidityProvider?: LiquidityProvider;
  /**
   *
   * @type {PayoutFailureReason}
   * @memberof Payout
   */
  failureReason?: PayoutFailureReason;
  /**
   * Crypto currency with optional network identifier attached.
   * List of crypto currencies Simplex might send you:
   * - USDT
   * - TRX
   * - BTC
   * - USDP
   * - AAVE
   * - USDC
   * - UNI
   * - COTI-ERC20
   * - TUSD
   * - COTI
   * - BUSD
   * - HTR
   * - USDT-TRC20
   * @type {string}
   * @memberof Payout
   */
  cryptoCurrency?: string;
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof Payout
   */
  destinationAddress?: CryptoWalletAddressWithTag;
  /**
   *
   * @type {FiatMoney}
   * @memberof Payout
   */
  requestedFiat: FiatMoney;
  /**
   *
   * @type {PayoutStatus}
   * @memberof Payout
   */
  status: PayoutStatus;
  /**
   * A message to the end-user.
   * @type {string}
   * @memberof Payout
   */
  memo?: string;
  /**
   *
   * @type {SuccessUrl}
   * @memberof Payout
   */
  successUrl: SuccessUrl;
  /**
   *
   * @type {FailureUrl}
   * @memberof Payout
   */
  failureUrl: FailureUrl;
  /**
   *
   * @type {CryptoCurrencyMetadata}
   * @memberof Payout
   */
  cryptoCurrencyMetadata?: CryptoCurrencyMetadata;
}

/**
 * Check if a given object implements the Payout interface.
 */
export function instanceOfPayout(value: object): value is Payout {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('orderId' in value) || value['orderId'] === undefined) return false;
  if (!('trafficProvider' in value) || value['trafficProvider'] === undefined) return false;
  if (!('requestedFiat' in value) || value['requestedFiat'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  if (!('successUrl' in value) || value['successUrl'] === undefined) return false;
  if (!('failureUrl' in value) || value['failureUrl'] === undefined) return false;
  return true;
}

export function PayoutFromJSON(json: any): Payout {
  return PayoutFromJSONTyped(json, false);
}

export function PayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payout {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    cryptoExecutionId: json['crypto_execution_id'] == null ? undefined : json['crypto_execution_id'],
    order: json['order'] == null ? undefined : PayoutOrderFromJSON(json['order']),
    orderId: OrderIdFromJSON(json['order_id']),
    trafficProvider: TrafficProviderFromJSON(json['traffic_provider']),
    liquidityProvider:
      json['liquidity_provider'] == null ? undefined : LiquidityProviderFromJSON(json['liquidity_provider']),
    failureReason: json['failure_reason'] == null ? undefined : PayoutFailureReasonFromJSON(json['failure_reason']),
    cryptoCurrency: json['crypto_currency'] == null ? undefined : json['crypto_currency'],
    destinationAddress:
      json['destination_address'] == null ? undefined : CryptoWalletAddressWithTagFromJSON(json['destination_address']),
    requestedFiat: FiatMoneyFromJSON(json['requested_fiat']),
    status: PayoutStatusFromJSON(json['status']),
    memo: json['memo'] == null ? undefined : json['memo'],
    successUrl: SuccessUrlFromJSON(json['success_url']),
    failureUrl: FailureUrlFromJSON(json['failure_url']),
    cryptoCurrencyMetadata:
      json['crypto_currency_metadata'] == null
        ? undefined
        : CryptoCurrencyMetadataFromJSON(json['crypto_currency_metadata']),
  };
}

export function PayoutToJSON(json: any): Payout {
  return PayoutToJSONTyped(json, false);
}

export function PayoutToJSONTyped(value?: Payout | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    crypto_execution_id: value['cryptoExecutionId'],
    order: PayoutOrderToJSON(value['order']),
    order_id: OrderIdToJSON(value['orderId']),
    traffic_provider: TrafficProviderToJSON(value['trafficProvider']),
    liquidity_provider: LiquidityProviderToJSON(value['liquidityProvider']),
    failure_reason: PayoutFailureReasonToJSON(value['failureReason']),
    crypto_currency: value['cryptoCurrency'],
    destination_address: CryptoWalletAddressWithTagToJSON(value['destinationAddress']),
    requested_fiat: FiatMoneyToJSON(value['requestedFiat']),
    status: PayoutStatusToJSON(value['status']),
    memo: value['memo'],
    success_url: SuccessUrlToJSON(value['successUrl']),
    failure_url: FailureUrlToJSON(value['failureUrl']),
    crypto_currency_metadata: CryptoCurrencyMetadataToJSON(value['cryptoCurrencyMetadata']),
  };
}
