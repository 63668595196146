/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { CryptoTransfer, CryptoTransferCreate, CryptoTransferCreated, ProblemDetails } from '../models/index';
import {
  CryptoTransferFromJSON,
  CryptoTransferToJSON,
  CryptoTransferCreateFromJSON,
  CryptoTransferCreateToJSON,
  CryptoTransferCreatedFromJSON,
  CryptoTransferCreatedToJSON,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from '../models/index';

export interface CreateCryptoTransferRequest {
  cryptoTransferCreate: CryptoTransferCreate;
}

export interface GetCryptoTransferRequest {
  id: string;
}

export interface GetCryptoTransferByPaymentIntentIdRequest {
  id: string;
}

/**
 *
 */
export class CryptoTransfersApi extends runtime.BaseAPI {
  /**
   */
  async createCryptoTransferRaw(
    requestParameters: CreateCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoTransferCreated>> {
    if (requestParameters['cryptoTransferCreate'] == null) {
      throw new runtime.RequiredError(
        'cryptoTransferCreate',
        'Required parameter "cryptoTransferCreate" was null or undefined when calling createCryptoTransfer().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/crypto-transfers`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CryptoTransferCreateToJSON(requestParameters['cryptoTransferCreate']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoTransferCreatedFromJSON(jsonValue));
  }

  /**
   */
  async createCryptoTransfer(
    requestParameters: CreateCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoTransferCreated> {
    const response = await this.createCryptoTransferRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCryptoTransferRaw(
    requestParameters: GetCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoTransfer>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling getCryptoTransfer().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/crypto-transfers/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoTransferFromJSON(jsonValue));
  }

  /**
   */
  async getCryptoTransfer(
    requestParameters: GetCryptoTransferRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoTransfer> {
    const response = await this.getCryptoTransferRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCryptoTransferByPaymentIntentIdRaw(
    requestParameters: GetCryptoTransferByPaymentIntentIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoTransfer>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling getCryptoTransferByPaymentIntentId().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/payment-intents/{id}/crypto-transfers`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoTransferFromJSON(jsonValue));
  }

  /**
   */
  async getCryptoTransferByPaymentIntentId(
    requestParameters: GetCryptoTransferByPaymentIntentIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoTransfer> {
    const response = await this.getCryptoTransferByPaymentIntentIdRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
