/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OtpVerification
 */
export interface OtpVerification {
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof OtpVerification
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof OtpVerification
   */
  expiresAt: Date;
  /**
   * The duration for which the verification is valid.
   * @type {number}
   * @memberof OtpVerification
   */
  durationSeconds: number;
  /**
   * Email hint to which the OTP was sent
   * @type {string}
   * @memberof OtpVerification
   */
  emailHint: string;
}

/**
 * Check if a given object implements the OtpVerification interface.
 */
export function instanceOfOtpVerification(value: object): value is OtpVerification {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('expiresAt' in value) || value['expiresAt'] === undefined) return false;
  if (!('durationSeconds' in value) || value['durationSeconds'] === undefined) return false;
  if (!('emailHint' in value) || value['emailHint'] === undefined) return false;
  return true;
}

export function OtpVerificationFromJSON(json: any): OtpVerification {
  return OtpVerificationFromJSONTyped(json, false);
}

export function OtpVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtpVerification {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    expiresAt: new Date(json['expires_at']),
    durationSeconds: json['duration_seconds'],
    emailHint: json['email_hint'],
  };
}

export function OtpVerificationToJSON(json: any): OtpVerification {
  return OtpVerificationToJSONTyped(json, false);
}

export function OtpVerificationToJSONTyped(value?: OtpVerification | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    expires_at: value['expiresAt'].toISOString(),
    duration_seconds: value['durationSeconds'],
    email_hint: value['emailHint'],
  };
}
