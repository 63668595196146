/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
  CryptoWalletAddressWithTagToJSONTyped,
} from './CryptoWalletAddressWithTag';

/**
 *
 * @export
 * @interface PayoutOrderCreate
 */
export interface PayoutOrderCreate {
  /**
   * Crypto currency with optional network identifier attached.
   * List of crypto currencies Simplex might send you:
   * - USDT
   * - TRX
   * - BTC
   * - USDP
   * - AAVE
   * - USDC
   * - UNI
   * - COTI-ERC20
   * - TUSD
   * - COTI
   * - BUSD
   * - HTR
   * - USDT-TRC20
   * @type {string}
   * @memberof PayoutOrderCreate
   */
  cryptoCurrency: string;
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof PayoutOrderCreate
   */
  destinationWallet: CryptoWalletAddressWithTag;
  /**
   *
   * @type {string}
   * @memberof PayoutOrderCreate
   */
  verificationToken: string;
}

/**
 * Check if a given object implements the PayoutOrderCreate interface.
 */
export function instanceOfPayoutOrderCreate(value: object): value is PayoutOrderCreate {
  if (!('cryptoCurrency' in value) || value['cryptoCurrency'] === undefined) return false;
  if (!('destinationWallet' in value) || value['destinationWallet'] === undefined) return false;
  if (!('verificationToken' in value) || value['verificationToken'] === undefined) return false;
  return true;
}

export function PayoutOrderCreateFromJSON(json: any): PayoutOrderCreate {
  return PayoutOrderCreateFromJSONTyped(json, false);
}

export function PayoutOrderCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutOrderCreate {
  if (json == null) {
    return json;
  }
  return {
    cryptoCurrency: json['crypto_currency'],
    destinationWallet: CryptoWalletAddressWithTagFromJSON(json['destination_wallet']),
    verificationToken: json['verification_token'],
  };
}

export function PayoutOrderCreateToJSON(json: any): PayoutOrderCreate {
  return PayoutOrderCreateToJSONTyped(json, false);
}

export function PayoutOrderCreateToJSONTyped(
  value?: PayoutOrderCreate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    crypto_currency: value['cryptoCurrency'],
    destination_wallet: CryptoWalletAddressWithTagToJSON(value['destinationWallet']),
    verification_token: value['verificationToken'],
  };
}
