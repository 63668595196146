/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  PaymentIntent,
  PaymentIntentAutomaticRefundPatch,
  PaymentIntentMerchantRefundPatch,
  PaymentIntentOffers,
  PaymentIntentOrderCreate,
  ProblemDetails,
} from '../models/index';
import {
  PaymentIntentFromJSON,
  PaymentIntentToJSON,
  PaymentIntentAutomaticRefundPatchFromJSON,
  PaymentIntentAutomaticRefundPatchToJSON,
  PaymentIntentMerchantRefundPatchFromJSON,
  PaymentIntentMerchantRefundPatchToJSON,
  PaymentIntentOffersFromJSON,
  PaymentIntentOffersToJSON,
  PaymentIntentOrderCreateFromJSON,
  PaymentIntentOrderCreateToJSON,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from '../models/index';

export interface CreateOrderRequest {
  id: string;
  paymentIntentOrderCreate: PaymentIntentOrderCreate;
}

export interface GetPaymentIntentRequest {
  id: string;
}

export interface ListOffersRequest {
  id: string;
}

export interface UpdateAutomaticRefundRequest {
  id: string;
  automaticRefundId: string;
  xIdempotencyKey: string;
  paymentIntentAutomaticRefundPatch: PaymentIntentAutomaticRefundPatch;
}

export interface UpdateMerchantRefundRequest {
  id: string;
  merchantRefundId: string;
  xIdempotencyKey: string;
  paymentIntentMerchantRefundPatch: PaymentIntentMerchantRefundPatch;
}

/**
 *
 */
export class PaymentIntentsApi extends runtime.BaseAPI {
  /**
   */
  async createOrderRaw(
    requestParameters: CreateOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentIntent>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling createOrder().',
      );
    }

    if (requestParameters['paymentIntentOrderCreate'] == null) {
      throw new runtime.RequiredError(
        'paymentIntentOrderCreate',
        'Required parameter "paymentIntentOrderCreate" was null or undefined when calling createOrder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/payment-intents/{id}/orders`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PaymentIntentOrderCreateToJSON(requestParameters['paymentIntentOrderCreate']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaymentIntentFromJSON(jsonValue));
  }

  /**
   */
  async createOrder(
    requestParameters: CreateOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentIntent> {
    const response = await this.createOrderRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getPaymentIntentRaw(
    requestParameters: GetPaymentIntentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentIntent>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling getPaymentIntent().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/payment-intents/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaymentIntentFromJSON(jsonValue));
  }

  /**
   */
  async getPaymentIntent(
    requestParameters: GetPaymentIntentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentIntent> {
    const response = await this.getPaymentIntentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async listOffersRaw(
    requestParameters: ListOffersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentIntentOffers>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling listOffers().');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/payment-intents/{id}/offers`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaymentIntentOffersFromJSON(jsonValue));
  }

  /**
   */
  async listOffers(
    requestParameters: ListOffersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentIntentOffers> {
    const response = await this.listOffersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateAutomaticRefundRaw(
    requestParameters: UpdateAutomaticRefundRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentIntent>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling updateAutomaticRefund().',
      );
    }

    if (requestParameters['automaticRefundId'] == null) {
      throw new runtime.RequiredError(
        'automaticRefundId',
        'Required parameter "automaticRefundId" was null or undefined when calling updateAutomaticRefund().',
      );
    }

    if (requestParameters['xIdempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'xIdempotencyKey',
        'Required parameter "xIdempotencyKey" was null or undefined when calling updateAutomaticRefund().',
      );
    }

    if (requestParameters['paymentIntentAutomaticRefundPatch'] == null) {
      throw new runtime.RequiredError(
        'paymentIntentAutomaticRefundPatch',
        'Required parameter "paymentIntentAutomaticRefundPatch" was null or undefined when calling updateAutomaticRefund().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xIdempotencyKey'] != null) {
      headerParameters['x-idempotency-key'] = String(requestParameters['xIdempotencyKey']);
    }

    const response = await this.request(
      {
        path: `/api/v1/payment-intents/{id}/automatic-refunds/{automatic_refund_id}`
          .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id'])))
          .replace(`{${'automatic_refund_id'}}`, encodeURIComponent(String(requestParameters['automaticRefundId']))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PaymentIntentAutomaticRefundPatchToJSON(requestParameters['paymentIntentAutomaticRefundPatch']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaymentIntentFromJSON(jsonValue));
  }

  /**
   */
  async updateAutomaticRefund(
    requestParameters: UpdateAutomaticRefundRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentIntent> {
    const response = await this.updateAutomaticRefundRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateMerchantRefundRaw(
    requestParameters: UpdateMerchantRefundRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentIntent>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling updateMerchantRefund().',
      );
    }

    if (requestParameters['merchantRefundId'] == null) {
      throw new runtime.RequiredError(
        'merchantRefundId',
        'Required parameter "merchantRefundId" was null or undefined when calling updateMerchantRefund().',
      );
    }

    if (requestParameters['xIdempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'xIdempotencyKey',
        'Required parameter "xIdempotencyKey" was null or undefined when calling updateMerchantRefund().',
      );
    }

    if (requestParameters['paymentIntentMerchantRefundPatch'] == null) {
      throw new runtime.RequiredError(
        'paymentIntentMerchantRefundPatch',
        'Required parameter "paymentIntentMerchantRefundPatch" was null or undefined when calling updateMerchantRefund().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xIdempotencyKey'] != null) {
      headerParameters['x-idempotency-key'] = String(requestParameters['xIdempotencyKey']);
    }

    const response = await this.request(
      {
        path: `/api/v1/payment-intents/{id}/merchant-refunds/{merchant_refund_id}`
          .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id'])))
          .replace(`{${'merchant_refund_id'}}`, encodeURIComponent(String(requestParameters['merchantRefundId']))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PaymentIntentMerchantRefundPatchToJSON(requestParameters['paymentIntentMerchantRefundPatch']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PaymentIntentFromJSON(jsonValue));
  }

  /**
   */
  async updateMerchantRefund(
    requestParameters: UpdateMerchantRefundRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentIntent> {
    const response = await this.updateMerchantRefundRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
