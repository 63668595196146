/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PaymentIntentOffer
 */
export interface PaymentIntentOffer {
  /**
   * Crypto currency with optional network identifier attached.
   * List of crypto currencies Simplex might send you:
   * - USDT
   * - TRX
   * - BTC
   * - USDP
   * - AAVE
   * - USDC
   * - UNI
   * - COTI-ERC20
   * - TUSD
   * - COTI
   * - BUSD
   * - HTR
   * - USDT-TRC20
   * @type {string}
   * @memberof PaymentIntentOffer
   */
  cryptoCurrency: string;
  /**
   *
   * @type {string}
   * @memberof PaymentIntentOffer
   */
  cryptoCurrencyName: string;
  /**
   *
   * @type {string}
   * @memberof PaymentIntentOffer
   */
  cryptoCurrencyNetworkName: string;
  /**
   * URI
   * @type {string}
   * @memberof PaymentIntentOffer
   */
  cryptoCurrencyLogo: string;
}

/**
 * Check if a given object implements the PaymentIntentOffer interface.
 */
export function instanceOfPaymentIntentOffer(value: object): value is PaymentIntentOffer {
  if (!('cryptoCurrency' in value) || value['cryptoCurrency'] === undefined) return false;
  if (!('cryptoCurrencyName' in value) || value['cryptoCurrencyName'] === undefined) return false;
  if (!('cryptoCurrencyNetworkName' in value) || value['cryptoCurrencyNetworkName'] === undefined) return false;
  if (!('cryptoCurrencyLogo' in value) || value['cryptoCurrencyLogo'] === undefined) return false;
  return true;
}

export function PaymentIntentOfferFromJSON(json: any): PaymentIntentOffer {
  return PaymentIntentOfferFromJSONTyped(json, false);
}

export function PaymentIntentOfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentIntentOffer {
  if (json == null) {
    return json;
  }
  return {
    cryptoCurrency: json['crypto_currency'],
    cryptoCurrencyName: json['crypto_currency_name'],
    cryptoCurrencyNetworkName: json['crypto_currency_network_name'],
    cryptoCurrencyLogo: json['crypto_currency_logo'],
  };
}

export function PaymentIntentOfferToJSON(json: any): PaymentIntentOffer {
  return PaymentIntentOfferToJSONTyped(json, false);
}

export function PaymentIntentOfferToJSONTyped(
  value?: PaymentIntentOffer | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    crypto_currency: value['cryptoCurrency'],
    crypto_currency_name: value['cryptoCurrencyName'],
    crypto_currency_network_name: value['cryptoCurrencyNetworkName'],
    crypto_currency_logo: value['cryptoCurrencyLogo'],
  };
}
