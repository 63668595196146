/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentIntentAutomaticRefundStatus } from './PaymentIntentAutomaticRefundStatus';
import {
  PaymentIntentAutomaticRefundStatusFromJSON,
  PaymentIntentAutomaticRefundStatusFromJSONTyped,
  PaymentIntentAutomaticRefundStatusToJSON,
  PaymentIntentAutomaticRefundStatusToJSONTyped,
} from './PaymentIntentAutomaticRefundStatus';
import type { CryptoMoney } from './CryptoMoney';
import {
  CryptoMoneyFromJSON,
  CryptoMoneyFromJSONTyped,
  CryptoMoneyToJSON,
  CryptoMoneyToJSONTyped,
} from './CryptoMoney';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
  CryptoWalletAddressWithTagToJSONTyped,
} from './CryptoWalletAddressWithTag';
import type { PaymentIntentAutomaticRefundOrder } from './PaymentIntentAutomaticRefundOrder';
import {
  PaymentIntentAutomaticRefundOrderFromJSON,
  PaymentIntentAutomaticRefundOrderFromJSONTyped,
  PaymentIntentAutomaticRefundOrderToJSON,
  PaymentIntentAutomaticRefundOrderToJSONTyped,
} from './PaymentIntentAutomaticRefundOrder';
import type { PaymentIntentAutomaticRefundReason } from './PaymentIntentAutomaticRefundReason';
import {
  PaymentIntentAutomaticRefundReasonFromJSON,
  PaymentIntentAutomaticRefundReasonFromJSONTyped,
  PaymentIntentAutomaticRefundReasonToJSON,
  PaymentIntentAutomaticRefundReasonToJSONTyped,
} from './PaymentIntentAutomaticRefundReason';

/**
 *
 * @export
 * @interface PaymentIntentAutomaticRefund
 */
export interface PaymentIntentAutomaticRefund {
  /**
   * Transaction id identifying exact transaction in blockchain
   * @type {string}
   * @memberof PaymentIntentAutomaticRefund
   */
  blockchainTransactionHash?: string;
  /**
   * UUID v4 formatted string
   * @type {string}
   * @memberof PaymentIntentAutomaticRefund
   */
  id: string;
  /**
   *
   * @type {PaymentIntentAutomaticRefundOrder}
   * @memberof PaymentIntentAutomaticRefund
   */
  order?: PaymentIntentAutomaticRefundOrder;
  /**
   *
   * @type {PaymentIntentAutomaticRefundReason}
   * @memberof PaymentIntentAutomaticRefund
   */
  reason: PaymentIntentAutomaticRefundReason;
  /**
   *
   * @type {CryptoMoney}
   * @memberof PaymentIntentAutomaticRefund
   */
  refundCrypto?: CryptoMoney;
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof PaymentIntentAutomaticRefund
   */
  refundDestination?: CryptoWalletAddressWithTag;
  /**
   *
   * @type {PaymentIntentAutomaticRefundStatus}
   * @memberof PaymentIntentAutomaticRefund
   */
  status: PaymentIntentAutomaticRefundStatus;
}

/**
 * Check if a given object implements the PaymentIntentAutomaticRefund interface.
 */
export function instanceOfPaymentIntentAutomaticRefund(value: object): value is PaymentIntentAutomaticRefund {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('reason' in value) || value['reason'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function PaymentIntentAutomaticRefundFromJSON(json: any): PaymentIntentAutomaticRefund {
  return PaymentIntentAutomaticRefundFromJSONTyped(json, false);
}

export function PaymentIntentAutomaticRefundFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentAutomaticRefund {
  if (json == null) {
    return json;
  }
  return {
    blockchainTransactionHash:
      json['blockchain_transaction_hash'] == null ? undefined : json['blockchain_transaction_hash'],
    id: json['id'],
    order: json['order'] == null ? undefined : PaymentIntentAutomaticRefundOrderFromJSON(json['order']),
    reason: PaymentIntentAutomaticRefundReasonFromJSON(json['reason']),
    refundCrypto: json['refund_crypto'] == null ? undefined : CryptoMoneyFromJSON(json['refund_crypto']),
    refundDestination:
      json['refund_destination'] == null ? undefined : CryptoWalletAddressWithTagFromJSON(json['refund_destination']),
    status: PaymentIntentAutomaticRefundStatusFromJSON(json['status']),
  };
}

export function PaymentIntentAutomaticRefundToJSON(json: any): PaymentIntentAutomaticRefund {
  return PaymentIntentAutomaticRefundToJSONTyped(json, false);
}

export function PaymentIntentAutomaticRefundToJSONTyped(
  value?: PaymentIntentAutomaticRefund | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    blockchain_transaction_hash: value['blockchainTransactionHash'],
    id: value['id'],
    order: PaymentIntentAutomaticRefundOrderToJSON(value['order']),
    reason: PaymentIntentAutomaticRefundReasonToJSON(value['reason']),
    refund_crypto: CryptoMoneyToJSON(value['refundCrypto']),
    refund_destination: CryptoWalletAddressWithTagToJSON(value['refundDestination']),
    status: PaymentIntentAutomaticRefundStatusToJSON(value['status']),
  };
}
