/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PayoutOffer } from './PayoutOffer';
import {
  PayoutOfferFromJSON,
  PayoutOfferFromJSONTyped,
  PayoutOfferToJSON,
  PayoutOfferToJSONTyped,
} from './PayoutOffer';

/**
 *
 * @export
 * @interface PayoutOffers
 */
export interface PayoutOffers {
  /**
   *
   * @type {Array<PayoutOffer>}
   * @memberof PayoutOffers
   */
  offers?: Array<PayoutOffer>;
}

/**
 * Check if a given object implements the PayoutOffers interface.
 */
export function instanceOfPayoutOffers(value: object): value is PayoutOffers {
  return true;
}

export function PayoutOffersFromJSON(json: any): PayoutOffers {
  return PayoutOffersFromJSONTyped(json, false);
}

export function PayoutOffersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutOffers {
  if (json == null) {
    return json;
  }
  return {
    offers: json['offers'] == null ? undefined : (json['offers'] as Array<any>).map(PayoutOfferFromJSON),
  };
}

export function PayoutOffersToJSON(json: any): PayoutOffers {
  return PayoutOffersToJSONTyped(json, false);
}

export function PayoutOffersToJSONTyped(value?: PayoutOffers | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    offers: value['offers'] == null ? undefined : (value['offers'] as Array<any>).map(PayoutOfferToJSON),
  };
}
