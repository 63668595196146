/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OtpVerificationCreateReference
 */
export interface OtpVerificationCreateReference {
  /**
   *
   * @type {string}
   * @memberof OtpVerificationCreateReference
   */
  payoutId?: string;
  /**
   *
   * @type {string}
   * @memberof OtpVerificationCreateReference
   */
  merchantRefundId?: string;
  /**
   *
   * @type {string}
   * @memberof OtpVerificationCreateReference
   */
  automaticRefundId?: string;
}

/**
 * Check if a given object implements the OtpVerificationCreateReference interface.
 */
export function instanceOfOtpVerificationCreateReference(value: object): value is OtpVerificationCreateReference {
  return true;
}

export function OtpVerificationCreateReferenceFromJSON(json: any): OtpVerificationCreateReference {
  return OtpVerificationCreateReferenceFromJSONTyped(json, false);
}

export function OtpVerificationCreateReferenceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OtpVerificationCreateReference {
  if (json == null) {
    return json;
  }
  return {
    payoutId: json['payout_id'] == null ? undefined : json['payout_id'],
    merchantRefundId: json['merchant_refund_id'] == null ? undefined : json['merchant_refund_id'],
    automaticRefundId: json['automatic_refund_id'] == null ? undefined : json['automatic_refund_id'],
  };
}

export function OtpVerificationCreateReferenceToJSON(json: any): OtpVerificationCreateReference {
  return OtpVerificationCreateReferenceToJSONTyped(json, false);
}

export function OtpVerificationCreateReferenceToJSONTyped(
  value?: OtpVerificationCreateReference | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    payout_id: value['payoutId'],
    merchant_refund_id: value['merchantRefundId'],
    automatic_refund_id: value['automaticRefundId'],
  };
}
