/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OtpConfirmationCreate
 */
export interface OtpConfirmationCreate {
  /**
   * OTP code received in the email
   * @type {string}
   * @memberof OtpConfirmationCreate
   */
  otpCode: string;
}

/**
 * Check if a given object implements the OtpConfirmationCreate interface.
 */
export function instanceOfOtpConfirmationCreate(value: object): value is OtpConfirmationCreate {
  if (!('otpCode' in value) || value['otpCode'] === undefined) return false;
  return true;
}

export function OtpConfirmationCreateFromJSON(json: any): OtpConfirmationCreate {
  return OtpConfirmationCreateFromJSONTyped(json, false);
}

export function OtpConfirmationCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtpConfirmationCreate {
  if (json == null) {
    return json;
  }
  return {
    otpCode: json['otp_code'],
  };
}

export function OtpConfirmationCreateToJSON(json: any): OtpConfirmationCreate {
  return OtpConfirmationCreateToJSONTyped(json, false);
}

export function OtpConfirmationCreateToJSONTyped(
  value?: OtpConfirmationCreate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    otp_code: value['otpCode'],
  };
}
