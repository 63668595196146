/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  OtpConfirmationCreate,
  OtpVerification,
  OtpVerificationCreate,
  OtpVerificationToken,
  ProblemDetails,
} from '../models/index';
import {
  OtpConfirmationCreateFromJSON,
  OtpConfirmationCreateToJSON,
  OtpVerificationFromJSON,
  OtpVerificationToJSON,
  OtpVerificationCreateFromJSON,
  OtpVerificationCreateToJSON,
  OtpVerificationTokenFromJSON,
  OtpVerificationTokenToJSON,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from '../models/index';

export interface CreateOtpVerificationRequest {
  otpVerificationCreate?: OtpVerificationCreate;
}

export interface CreateOtpVerificationConfirmationRequest {
  verificationId: string;
  otpConfirmationCreate?: OtpConfirmationCreate;
}

/**
 *
 */
export class OtpApi extends runtime.BaseAPI {
  /**
   */
  async createOtpVerificationRaw(
    requestParameters: CreateOtpVerificationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OtpVerification>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/otp-verifications`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OtpVerificationCreateToJSON(requestParameters['otpVerificationCreate']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OtpVerificationFromJSON(jsonValue));
  }

  /**
   */
  async createOtpVerification(
    requestParameters: CreateOtpVerificationRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OtpVerification> {
    const response = await this.createOtpVerificationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async createOtpVerificationConfirmationRaw(
    requestParameters: CreateOtpVerificationConfirmationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OtpVerificationToken>> {
    if (requestParameters['verificationId'] == null) {
      throw new runtime.RequiredError(
        'verificationId',
        'Required parameter "verificationId" was null or undefined when calling createOtpVerificationConfirmation().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/otp-confirmations/{verification_id}`.replace(
          `{${'verification_id'}}`,
          encodeURIComponent(String(requestParameters['verificationId'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: OtpConfirmationCreateToJSON(requestParameters['otpConfirmationCreate']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OtpVerificationTokenFromJSON(jsonValue));
  }

  /**
   */
  async createOtpVerificationConfirmation(
    requestParameters: CreateOtpVerificationConfirmationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OtpVerificationToken> {
    const response = await this.createOtpVerificationConfirmationRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
