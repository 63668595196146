/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { CryptoExecution, ProblemDetails } from '../models/index';
import {
  CryptoExecutionFromJSON,
  CryptoExecutionToJSON,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from '../models/index';

export interface GetCryptoExecutionRequest {
  id: string;
}

export interface GetCryptoExecutionByPaymentIntentIdMerchantRefundIdRequest {
  id: string;
  merchantRefundId: string;
}

/**
 *
 */
export class CryptoExecutionsApi extends runtime.BaseAPI {
  /**
   */
  async getCryptoExecutionRaw(
    requestParameters: GetCryptoExecutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoExecution>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling getCryptoExecution().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/crypto-executions/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoExecutionFromJSON(jsonValue));
  }

  /**
   */
  async getCryptoExecution(
    requestParameters: GetCryptoExecutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoExecution> {
    const response = await this.getCryptoExecutionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCryptoExecutionByPaymentIntentIdMerchantRefundIdRaw(
    requestParameters: GetCryptoExecutionByPaymentIntentIdMerchantRefundIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CryptoExecution>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling getCryptoExecutionByPaymentIntentIdMerchantRefundId().',
      );
    }

    if (requestParameters['merchantRefundId'] == null) {
      throw new runtime.RequiredError(
        'merchantRefundId',
        'Required parameter "merchantRefundId" was null or undefined when calling getCryptoExecutionByPaymentIntentIdMerchantRefundId().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/payment-intents/{id}/merchant-refunds/{merchant_refund_id}/crypto-executions`
          .replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id'])))
          .replace(`{${'merchant_refund_id'}}`, encodeURIComponent(String(requestParameters['merchantRefundId']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CryptoExecutionFromJSON(jsonValue));
  }

  /**
   */
  async getCryptoExecutionByPaymentIntentIdMerchantRefundId(
    requestParameters: GetCryptoExecutionByPaymentIntentIdMerchantRefundIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CryptoExecution> {
    const response = await this.getCryptoExecutionByPaymentIntentIdMerchantRefundIdRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
