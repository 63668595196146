/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LiquidityProvider
 */
export interface LiquidityProvider {
  /**
   * Liquidity provider id
   * @type {string}
   * @memberof LiquidityProvider
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof LiquidityProvider
   */
  displayName: string;
  /**
   * Liquidity provider address, used in hamburger menu footer block
   * @type {string}
   * @memberof LiquidityProvider
   */
  address: string;
  /**
   * Liquidity provider terms and conditions url
   * @type {string}
   * @memberof LiquidityProvider
   */
  termsAndConditionsUrl: string;
  /**
   * Liquidity provider privacy url
   * @type {string}
   * @memberof LiquidityProvider
   */
  privacyPolicyUrl: string;
  /**
   * Liquidity provider and Simplex combined terms and conditions url
   * @type {string}
   * @memberof LiquidityProvider
   */
  termsAndConditionsCombinedUrl: string;
  /**
   * Liquidity provider and Simplex combined privacy url
   * @type {string}
   * @memberof LiquidityProvider
   */
  privacyPolicyCombinedUrl: string;
}

/**
 * Check if a given object implements the LiquidityProvider interface.
 */
export function instanceOfLiquidityProvider(value: object): value is LiquidityProvider {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('displayName' in value) || value['displayName'] === undefined) return false;
  if (!('address' in value) || value['address'] === undefined) return false;
  if (!('termsAndConditionsUrl' in value) || value['termsAndConditionsUrl'] === undefined) return false;
  if (!('privacyPolicyUrl' in value) || value['privacyPolicyUrl'] === undefined) return false;
  if (!('termsAndConditionsCombinedUrl' in value) || value['termsAndConditionsCombinedUrl'] === undefined) return false;
  if (!('privacyPolicyCombinedUrl' in value) || value['privacyPolicyCombinedUrl'] === undefined) return false;
  return true;
}

export function LiquidityProviderFromJSON(json: any): LiquidityProvider {
  return LiquidityProviderFromJSONTyped(json, false);
}

export function LiquidityProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiquidityProvider {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    displayName: json['display_name'],
    address: json['address'],
    termsAndConditionsUrl: json['terms_and_conditions_url'],
    privacyPolicyUrl: json['privacy_policy_url'],
    termsAndConditionsCombinedUrl: json['terms_and_conditions_combined_url'],
    privacyPolicyCombinedUrl: json['privacy_policy_combined_url'],
  };
}

export function LiquidityProviderToJSON(json: any): LiquidityProvider {
  return LiquidityProviderToJSONTyped(json, false);
}

export function LiquidityProviderToJSONTyped(
  value?: LiquidityProvider | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    display_name: value['displayName'],
    address: value['address'],
    terms_and_conditions_url: value['termsAndConditionsUrl'],
    privacy_policy_url: value['privacyPolicyUrl'],
    terms_and_conditions_combined_url: value['termsAndConditionsCombinedUrl'],
    privacy_policy_combined_url: value['privacyPolicyCombinedUrl'],
  };
}
