/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Payout failure reason
 * @export
 */
export const PayoutFailureReason = {
  LpRejected: 'LP_REJECTED',
  DestinationRejected: 'DESTINATION_REJECTED',
  Limited: 'LIMITED',
} as const;
export type PayoutFailureReason = (typeof PayoutFailureReason)[keyof typeof PayoutFailureReason];

export function instanceOfPayoutFailureReason(value: any): boolean {
  for (const key in PayoutFailureReason) {
    if (Object.prototype.hasOwnProperty.call(PayoutFailureReason, key)) {
      if (PayoutFailureReason[key as keyof typeof PayoutFailureReason] === value) {
        return true;
      }
    }
  }
  return false;
}

export function PayoutFailureReasonFromJSON(json: any): PayoutFailureReason {
  return PayoutFailureReasonFromJSONTyped(json, false);
}

export function PayoutFailureReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutFailureReason {
  return json as PayoutFailureReason;
}

export function PayoutFailureReasonToJSON(value?: PayoutFailureReason | null): any {
  return value as any;
}

export function PayoutFailureReasonToJSONTyped(value: any, ignoreDiscriminator: boolean): PayoutFailureReason {
  return value as PayoutFailureReason;
}
