/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { Payout, PayoutOffers, PayoutOrderCreate, ProblemDetails } from '../models/index';
import {
  PayoutFromJSON,
  PayoutToJSON,
  PayoutOffersFromJSON,
  PayoutOffersToJSON,
  PayoutOrderCreateFromJSON,
  PayoutOrderCreateToJSON,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
} from '../models/index';

export interface CreatePayoutOrderRequest {
  id: string;
  payoutOrderCreate: PayoutOrderCreate;
}

export interface GetPayoutRequest {
  id: string;
}

export interface ListPayoutOffersRequest {
  id: string;
}

/**
 *
 */
export class PayoutsApi extends runtime.BaseAPI {
  /**
   */
  async createPayoutOrderRaw(
    requestParameters: CreatePayoutOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Payout>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling createPayoutOrder().',
      );
    }

    if (requestParameters['payoutOrderCreate'] == null) {
      throw new runtime.RequiredError(
        'payoutOrderCreate',
        'Required parameter "payoutOrderCreate" was null or undefined when calling createPayoutOrder().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/payouts/{id}/orders`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PayoutOrderCreateToJSON(requestParameters['payoutOrderCreate']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayoutFromJSON(jsonValue));
  }

  /**
   */
  async createPayoutOrder(
    requestParameters: CreatePayoutOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Payout> {
    const response = await this.createPayoutOrderRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getPayoutRaw(
    requestParameters: GetPayoutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Payout>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getPayout().');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/payouts/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayoutFromJSON(jsonValue));
  }

  /**
   */
  async getPayout(
    requestParameters: GetPayoutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Payout> {
    const response = await this.getPayoutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async listPayoutOffersRaw(
    requestParameters: ListPayoutOffersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PayoutOffers>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling listPayoutOffers().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/payouts/{id}/offers`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters['id']))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayoutOffersFromJSON(jsonValue));
  }

  /**
   */
  async listPayoutOffers(
    requestParameters: ListPayoutOffersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PayoutOffers> {
    const response = await this.listPayoutOffersRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
