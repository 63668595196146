/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OtpVerificationToken
 */
export interface OtpVerificationToken {
  /**
   * Verification token to provide in the order creation request
   * @type {string}
   * @memberof OtpVerificationToken
   */
  verificationToken: string;
}

/**
 * Check if a given object implements the OtpVerificationToken interface.
 */
export function instanceOfOtpVerificationToken(value: object): value is OtpVerificationToken {
  if (!('verificationToken' in value) || value['verificationToken'] === undefined) return false;
  return true;
}

export function OtpVerificationTokenFromJSON(json: any): OtpVerificationToken {
  return OtpVerificationTokenFromJSONTyped(json, false);
}

export function OtpVerificationTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtpVerificationToken {
  if (json == null) {
    return json;
  }
  return {
    verificationToken: json['verification_token'],
  };
}

export function OtpVerificationTokenToJSON(json: any): OtpVerificationToken {
  return OtpVerificationTokenToJSONTyped(json, false);
}

export function OtpVerificationTokenToJSONTyped(
  value?: OtpVerificationToken | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    verification_token: value['verificationToken'],
  };
}
