/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Payment intent failure reason
 * @export
 */
export const PaymentIntentFailureReason = {
  Expired: 'EXPIRED',
  LpRejected: 'LP_REJECTED',
  Underpaid: 'UNDERPAID',
} as const;
export type PaymentIntentFailureReason = (typeof PaymentIntentFailureReason)[keyof typeof PaymentIntentFailureReason];

export function instanceOfPaymentIntentFailureReason(value: any): boolean {
  for (const key in PaymentIntentFailureReason) {
    if (Object.prototype.hasOwnProperty.call(PaymentIntentFailureReason, key)) {
      if (PaymentIntentFailureReason[key as keyof typeof PaymentIntentFailureReason] === value) {
        return true;
      }
    }
  }
  return false;
}

export function PaymentIntentFailureReasonFromJSON(json: any): PaymentIntentFailureReason {
  return PaymentIntentFailureReasonFromJSONTyped(json, false);
}

export function PaymentIntentFailureReasonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentFailureReason {
  return json as PaymentIntentFailureReason;
}

export function PaymentIntentFailureReasonToJSON(value?: PaymentIntentFailureReason | null): any {
  return value as any;
}

export function PaymentIntentFailureReasonToJSONTyped(
  value: any,
  ignoreDiscriminator: boolean,
): PaymentIntentFailureReason {
  return value as PaymentIntentFailureReason;
}
