import { type RouteRecord } from './router';
export type RouteName =
  | 'payment-intent-home'
  | 'offers'
  | 'send-crypto-consent'
  | 'send-crypto'
  | 'payment-expired'
  | 'payment-processing'
  | 'payment-success'
  | 'payment-failed'
  | 'payment-too-low'
  | 'payment-too-high'
  | 'automatic-refund-address'
  | 'merchant-refund-address'
  | 'automatic-refund-processing'
  | 'merchant-refund-processing'
  | 'merchant-refund-successful'
  | 'automatic-refund-successful'
  | 'automatic-refund-failed'
  | 'merchant-refund-failed'
  | 'payout-home'
  | 'payout-crypto-destination'
  | 'payout-crypto-currency'
  | 'payout-wallet-address'
  | 'payout-crypto-destination-verification'
  | 'payout-processing'
  | 'payout-failed'
  | 'payout-success'
  | 'not-found';

export const routes: Array<RouteRecord> = [
  {
    path: '/payment-intent/:id([\\da-f]{8}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{12})',
    name: 'payment-intent-home',
    component: () => import('../../payment-intent/home/PageHome.vue'),
    props: true,
    children: [
      {
        path: 'offers',
        name: 'offers',
        component: () => import('../../payment-intent/offers/PageOffers.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'send-crypto-consent',
        name: 'send-crypto-consent',
        component: () => import('../../payment-intent/send-crypto-consent/PageSendCryptoConsent.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'send-crypto',
        name: 'send-crypto',
        component: () => import('../../payment-intent/send-crypto/PageSendCrypto.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-expired',
        name: 'payment-expired',
        component: () => import('../../payment-intent/payment/expired/PagePaymentExpired.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-success',
        name: 'payment-success',
        component: () => import('../../payment-intent/payment/success/PagePaymentSuccess.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-processing',
        name: 'payment-processing',
        component: () => import('../../payment-intent/payment/processing/PagePaymentProcessing.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-too-low',
        name: 'payment-too-low',
        component: () => import('../../payment-intent/payment/too-low/PagePaymentTooLow.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-too-high',
        name: 'payment-too-high',
        component: () => import('../../payment-intent/payment/too-high/PagePaymentTooHigh.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payment-failed',
        name: 'payment-failed',
        component: () => import('../../payment-intent/payment/failed/PagePaymentFailed.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'automatic-refund-address',
        name: 'automatic-refund-address',
        component: () =>
          import('../../payment-intent/automatic-refund/automatic-address/PageAutomaticRefundAddress.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'merchant-refund-address',
        name: 'merchant-refund-address',
        component: () => import('../../payment-intent/merchant-refund/merchant-address/PageMerchantRefundAddress.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'automatic-refund-processing',
        name: 'automatic-refund-processing',
        component: () =>
          import('../../payment-intent/automatic-refund/automatic-refund-processing/PageAutomaticRefundProcessing.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'merchant-refund-processing',
        name: 'merchant-refund-processing',
        component: () =>
          import('../../payment-intent/merchant-refund/merchant-refund-processing/PageMerchantRefundProcessing.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'automatic-refund-failed',
        name: 'automatic-refund-failed',
        component: () =>
          import('../../payment-intent/automatic-refund/automatic-refund-failed/PageAutomaticRefundFailed.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'merchant-refund-failed',
        name: 'merchant-refund-failed',
        component: () =>
          import('../../payment-intent/merchant-refund/merchant-refund-failed/PageMerchantRefundFailed.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'automatic-refund-successful',
        name: 'automatic-refund-successful',
        component: () =>
          import('../../payment-intent/automatic-refund/automatic-refund-success/PageAutomaticRefundSuccessful.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'merchant-refund-successful',
        name: 'merchant-refund-successful',
        component: () =>
          import('../../payment-intent/merchant-refund/merchant-refund-success/PageMerchantRefundSuccessful.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
    ],
  },
  {
    path: '/payout/:id([\\da-f]{8}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{4}-[\\da-f]{12})',
    name: 'payout-home',
    component: () => import('../../payout/home/PageHome.vue'),
    props: true,
    children: [
      {
        path: 'crypto-destination',
        name: 'payout-crypto-destination',
        component: () => import('../../payout/crypto-destination/PageCryptoDestination.vue'),
        redirect: {
          name: 'payout-crypto-currency',
        },
        children: [
          {
            path: 'crypto-currency',
            name: 'payout-crypto-currency',
            component: () => import('../../payout/crypto-destination/crypto-currency/PageCryptoCurrency.vue'),
            meta: {
              disabledBackButton: true,
            },
          },
          {
            path: 'wallet-address',
            name: 'payout-wallet-address',
            component: () => import('../../payout/crypto-destination/wallet-address/PageWalletAddress.vue'),
            meta: {
              disabledBackButton: true,
            },
          },
          {
            path: 'crypto-destination-verification',
            name: 'payout-crypto-destination-verification',
            component: () =>
              import('../../payout/crypto-destination/verification/PageCryptoDestinationVerification.vue'),
            meta: {
              disabledBackButton: true,
            },
          },
        ],
      },
      {
        path: 'payout-processing',
        name: 'payout-processing',
        component: () => import('../../payout/payout-processing/PagePayoutProcessing.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payout-failed',
        name: 'payout-failed',
        component: () => import('../../payout/payout-failed/PagePayoutFailed.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
      {
        path: 'payout-success',
        name: 'payout-success',
        component: () => import('../../payout/payout-success/PagePayoutSuccessful.vue'),
        meta: {
          disabledBackButton: true,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../../not-found/PageNotFound.vue'),
  },
];
