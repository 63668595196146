/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CryptoWalletAddressWithTag } from './CryptoWalletAddressWithTag';
import {
  CryptoWalletAddressWithTagFromJSON,
  CryptoWalletAddressWithTagFromJSONTyped,
  CryptoWalletAddressWithTagToJSON,
  CryptoWalletAddressWithTagToJSONTyped,
} from './CryptoWalletAddressWithTag';

/**
 *
 * @export
 * @interface PaymentIntentAutomaticRefundPatch
 */
export interface PaymentIntentAutomaticRefundPatch {
  /**
   *
   * @type {CryptoWalletAddressWithTag}
   * @memberof PaymentIntentAutomaticRefundPatch
   */
  refundDestination: CryptoWalletAddressWithTag;
}

/**
 * Check if a given object implements the PaymentIntentAutomaticRefundPatch interface.
 */
export function instanceOfPaymentIntentAutomaticRefundPatch(value: object): value is PaymentIntentAutomaticRefundPatch {
  if (!('refundDestination' in value) || value['refundDestination'] === undefined) return false;
  return true;
}

export function PaymentIntentAutomaticRefundPatchFromJSON(json: any): PaymentIntentAutomaticRefundPatch {
  return PaymentIntentAutomaticRefundPatchFromJSONTyped(json, false);
}

export function PaymentIntentAutomaticRefundPatchFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentIntentAutomaticRefundPatch {
  if (json == null) {
    return json;
  }
  return {
    refundDestination: CryptoWalletAddressWithTagFromJSON(json['refund_destination']),
  };
}

export function PaymentIntentAutomaticRefundPatchToJSON(json: any): PaymentIntentAutomaticRefundPatch {
  return PaymentIntentAutomaticRefundPatchToJSONTyped(json, false);
}

export function PaymentIntentAutomaticRefundPatchToJSONTyped(
  value?: PaymentIntentAutomaticRefundPatch | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    refund_destination: CryptoWalletAddressWithTagToJSON(value['refundDestination']),
  };
}
