/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TrafficProvider
 */
export interface TrafficProvider {
  /**
   * Traffic provider id
   * @type {string}
   * @memberof TrafficProvider
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TrafficProvider
   */
  displayName: string;
  /**
   * Traffic provider logo url
   * @type {string}
   * @memberof TrafficProvider
   */
  logoUrl: string;
}

/**
 * Check if a given object implements the TrafficProvider interface.
 */
export function instanceOfTrafficProvider(value: object): value is TrafficProvider {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('displayName' in value) || value['displayName'] === undefined) return false;
  if (!('logoUrl' in value) || value['logoUrl'] === undefined) return false;
  return true;
}

export function TrafficProviderFromJSON(json: any): TrafficProvider {
  return TrafficProviderFromJSONTyped(json, false);
}

export function TrafficProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrafficProvider {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    displayName: json['display_name'],
    logoUrl: json['logo_url'],
  };
}

export function TrafficProviderToJSON(json: any): TrafficProvider {
  return TrafficProviderToJSONTyped(json, false);
}

export function TrafficProviderToJSONTyped(value?: TrafficProvider | null, ignoreDiscriminator: boolean = false): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    display_name: value['displayName'],
    logo_url: value['logoUrl'],
  };
}
