/* tslint:disable */
/* eslint-disable */
/**
 * pay-with-crypto
 * pay-with-crypto API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev.unicorn.team@nuvei.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OtpVerificationCreateReference } from './OtpVerificationCreateReference';
import {
  OtpVerificationCreateReferenceFromJSON,
  OtpVerificationCreateReferenceFromJSONTyped,
  OtpVerificationCreateReferenceToJSON,
  OtpVerificationCreateReferenceToJSONTyped,
} from './OtpVerificationCreateReference';

/**
 *
 * @export
 * @interface OtpVerificationCreate
 */
export interface OtpVerificationCreate {
  /**
   *
   * @type {OtpVerificationCreateReference}
   * @memberof OtpVerificationCreate
   */
  reference: OtpVerificationCreateReference | null;
}

/**
 * Check if a given object implements the OtpVerificationCreate interface.
 */
export function instanceOfOtpVerificationCreate(value: object): value is OtpVerificationCreate {
  if (!('reference' in value) || value['reference'] === undefined) return false;
  return true;
}

export function OtpVerificationCreateFromJSON(json: any): OtpVerificationCreate {
  return OtpVerificationCreateFromJSONTyped(json, false);
}

export function OtpVerificationCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtpVerificationCreate {
  if (json == null) {
    return json;
  }
  return {
    reference: OtpVerificationCreateReferenceFromJSON(json['reference']),
  };
}

export function OtpVerificationCreateToJSON(json: any): OtpVerificationCreate {
  return OtpVerificationCreateToJSONTyped(json, false);
}

export function OtpVerificationCreateToJSONTyped(
  value?: OtpVerificationCreate | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    reference: OtpVerificationCreateReferenceToJSON(value['reference']),
  };
}
